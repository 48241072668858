<!--
 * @Author: zhulin
 * @Date: 2021-05-14 11:52:25
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-19 11:32:09
 * @Description:关联规则弹窗
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\log\components\list.vue
-->
<template>
  <en-dialog title="关联规则" :visible="dialogVisible" width="1000px" append-to-body :close-on-click-modal="false" @close="closeDialog">
    <div class="list" v-loading="loadingLog">
      <en-table :data="tableData.records" height="400px" :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }">
        <en-table-column type="index" width="60px"></en-table-column>
        <en-table-column prop="ruleName" label="规则名称"> </en-table-column>
        <en-table-column prop="dataId" label="被执行数据">
          <template slot-scope="{ row, column }">
            <span style="color: #0fb7f5;cursor: pointer;" @click="jump(row, column)">
              {{ row.dataId }}
            </span>
          </template>
        </en-table-column>
        <en-table-column prop="status" label="执行状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 1">成功</el-tag>
            <el-tag type="warning" v-if="scope.row.status === 2">失败</el-tag>
          </template>
        </en-table-column>
        <en-table-column prop="result" label="执行结果"> </en-table-column>
        <en-table-column prop="eventType" label="是否触发">
          <template slot-scope="{row}">
            <span v-if="row.eventType === 3" style="color: #0fb7f5;cursor: pointer;" @click="openSubLogDialog(row)">
              {{row.content||"触发规则"}}
            </span>
            <span v-else>{{row.content}}</span>
          </template>
        </en-table-column>
        <en-table-column prop="createTime" label="操作时间"> </en-table-column>
      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { flowAndLog } from "@/api/businessModel";
import { newTabViewBusinessData } from "@/components/businessDetail/helper";

export default {
  name: "list",
  props: {
    id: {
      type: String,
      default: ""
    },
    dialogVisible: Boolean
  },
  data() {
    return {
      loadingLog: false,
      tableData: {},
      pageModel: {}
    };
  },
  computed: {},
  mounted() {
    this.queryList();
  },
  methods: {
    jump(rowData, col) {
      console.log(rowData);
      console.log(col);
      if (col.field === "eventType" && rowData.eventType === 3) {
        // window.open(`/toMdModelUserLog.do?b=${rowData.id}`, "_blank");
      } else if (col.field === "dataId" && rowData.dataId) {
        // 业务建模
        newTabViewBusinessData({
          templateId: rowData.templateId,
          id: rowData.dataId,
          businessType: 2
        });
      }
    },
    openSubLogDialog(row) {
      console.log(row);
      // this.$refs.logSubDialog.showDialog(row);
      const newPage = this.$router.resolve({
        path: "/businessModel/userLog",
        query: {
          templateId: row.id
        }
      });
      window.open(newPage.href, "_blank");
    },
    /**
     * @description: 查询日志数据
     * @param {businessKey}
     */
    async queryList(pageSize, pageNo) {
      try {
        this.loadingLog = true;
        const rsp = await flowAndLog.queryRuleUserLog({
          batchNo: this.id,
          pageSize: pageSize || 10,
          pageNo: pageNo || 1
        });
        this.tableData = rsp || {};
        this.pageModel = {
          pageNo: rsp.pageNo,
          pageSize: rsp.pageSize,
          total: rsp.total,
          totalPages: rsp.totalPages
        };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingLog = false;
      }
    },
    /**
     * @description: 页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.queryList(pageNo, pageSize);
    },
    /**
     * @description: 关闭弹窗回调
     * @param {type}
     * @return:
     */
    closeDialog() {
      this.$emit("update:dialogVisible", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
