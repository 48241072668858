<!-- eslint-disable no-use-before-define -->
<!--
 * @Author: 梁平贤
 * @Date: 2020-07-04 16:56:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-04 11:41:47
 * @Description:查看日志
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\log\index.vue
-->
<template>
  <div class="seeLog" v-if="this.businessData.openType === '2'">
    <el-collapse v-model="showContent" @change="changeShowList">
      <el-collapse-item :name="column.id">
        <template slot="title">
          <div class="comment-title" :class="{ 'dis-expand': expandStatus === 'shouqi' }">
            <div class="comment-tit-inner">
              <div class="txt">
                <span>操作日志</span>
              </div>
            </div>
          </div>
        </template>
        <div class="list-main" v-if="showList" v-loading="loadingLog">
          <div class="list" v-if="this.logData.records && this.logData.records.length > 0">
            <div class="year-row" v-for="(year, yearkey) in logTimeData" :key="yearkey">
              <div class="month-row" v-for="(month, monthkey) in year" :key="monthkey">
                <div class="month-mod">{{ monthkey }}月</div>
                <div class="day-row" v-for="(day, daykey) in month" :key="daykey">
                  <dl class="day-mod">
                    <!-- <span class="day-txt">{{ monthkey }}月</span> -->
                    <dd class="main-cent" v-for="(item, key) in day" :key="key">
                     <p class="time">{{ item.createTime }}</p>
                      <div class="cent-row">
                        <div class="head" :style='{"background":item.background || "#3E90FE"}'>
                          <!-- <en-icon name="iconwenjian-office" size="20"></en-icon> -->
                          <span >{{item.operateName.substring(0,1)}}</span>
                        </div>
                        <div class="cent">
                          <div class="name">
                            <span class="main-name">{{ item.operateName }}</span>
                          </div>
                          <p class="opinion link-cur" v-if="parseInt(item.eventType, 10) === 3" @click="toLink(item)">{{ item.content }}</p>
                          <p class="opinion" v-else>{{ item.content }}</p>
                        </div>
                        <div class="info">
                          <div class="btn-line">
                            <div class="btn-item" v-if="parseInt(item.eventType, 10) === 2" @click="seeFlowBtn(item.id)">
                              <en-icon name="iconshouqian" size="14"></en-icon>
                              <span class="txt">查看流程</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--'查看流程'-->
                      <div class="seeFlow-cent" v-if="parseInt(item.eventType, 10) === 2 && showFlow">
                        <cardList :column="column" :data="businessData" :itemInfo="item" :isLogIn="true" :showList="showFlow" />
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <en-result type="NoData" v-else></en-result>
        </div>
        <list :id="listid" v-if="showAlert" :dialogVisible.sync="showAlert" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import { flowAndLog } from "@/api/businessModel";

import cardList from "../seeFlow/components/cardList";
import list from "./components/list";

export default {
  name: "BMLogColumn",
  components: { cardList, list },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      logData: {}, // 请求到的流程数据
      logTimeData: [], // 处理后新的数据
      showList: false,
      showFlow: false,
      mdInfo: {},
      showContent: [],
      loadingLog: false,
      listid: "",
      showAlert: false,
      colorList: ["#3E90FE", "#46D48A", "#FFA32C", "#FD635B", "#48BAFF", "#A971F6", "#60647D"]
    };
  },
  mounted() {
    console.log(this.businessData, "logloglog");
  },
  filters: {
    timeTxt(item) {
      const t = dayjs(item).format("HH:mm:ss");
      return t;
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    /**
     * @description: 点击展开流程列表
     */
    changeShowList(val) {
      this.mdInfo = this.businessData.originData.mdInfo;
      // this.showList = !!val.length;
      // const show = this.showList;
      if (val.length) {
        this.mdLogList();
        this.showList = true;
      } else {
        this.showList = false;
      }
    },
    /**
     * @description: 查询日志数据
     * @param {businessKey}
     */
    async mdLogList() {
      try {
        this.loadingLog = true;
        const rsp = await flowAndLog.querymdLogList({
          id: this.businessData.id,
          // templateId: this.businessData.type === "1"? this.businessData.objTypeId : this.businessData.templateId,
          templateId: this.businessData.templateId,
          pageSize: 10,
          pageNo: 1
        });
        this.logData = rsp;
        this.getLogTimeData();
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingLog = false;
      }
    },
    /**
     * @description: 按照日期拼成新数据
     */
    getLogTimeData() {
      const dataMap = {};
      const rspData = (this.logData.records || []).map((v) => {
        const time = new Date(v.createTime);
        return { ...v, timeTemp: time.getTime() };
      }).sort((m, n) => n.timeTemp - m.timeTemp);
      let index = 0;
      for (let i = 0; i < rspData.length; i++) {
        const item = rspData[i].createTime;
        if (index >= 6) {
          index = 0;
        }
        rspData[i].background = this.colorList[index];
        index += 1;
        const y = item.substring(0, 4);
        const m = item.substring(5, 7);
        const d = item.substring(8, 10);
        if (!dataMap[y]) {
          dataMap[y] = {};
          dataMap[y][m] = {};
          dataMap[y][m][d] = [rspData[i]];
        } else if (!dataMap[y][m]) {
          dataMap[y][m] = {};
          dataMap[y][m][d] = [rspData[i]];
        } else if (!dataMap[y][m][d]) {
          dataMap[y][m][d] = [rspData[i]];
        } else {
          dataMap[y][m][d].unshift(rspData[i]);
        }
      }
      this.logTimeData = dataMap;
    },
    /**
     * @description: 关联规则弹窗
     * @param {id}
     */
    toLink(item) {
      this.showAlert = true;
      this.listid = item.id;
    },
    /**
     * @description: 查看流程
     * @param {}
     */
    seeFlowBtn() {
      this.showFlow = !this.showFlow;
    }
  }
};
</script>

<style lang="scss" scoped>
.seeLog {
  width: 100%;
  border: 1px solid #dce5ec;
  border-radius: 4px;
margin-top:20px;
  .comment-title {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold;
  }

    /deep/.el-collapse-item__header {
    background: #f6fafd;
    border-bottom: 1px solid #dce5ec;
    .el-collapse-item__arrow {
      color: #3e90fe;
      margin-right: 20px;
    }
  }
  /deep/.el-collapse-item__content{
    padding-bottom: 0;
  }

  .link-cur {
    color: #3e90fe;
    cursor: pointer;
  }
  .opinion {
    color: #636C78;
  }
  .top-head {
    // background-color: #f5f7fa;
    height: 48px;
    // padding-left: 21px;
    text-align: left;
    zoom: 1;
    display: flex;
    align-items: center;
    font-size: 12px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .txts {
      font-weight: bold;
      float: left;
      line-height: 48px;
      font-size: 14px;
    }
    .notices-txt {
      float: left;
      background: #f7f0eb;
      color: #fd9a4a;
      padding: 0 10px;
      border-radius: 30px;
      height: 22px;
      line-height: 22px;
      margin: 13px 0 0 10px;
    }
    .show-btn {
      float: right;
      color: #66a7e5;
      margin: 16px 18px 0 0;
      cursor: pointer;
    }
    .drop-down{
      margin-left: 5px;
      width:12px;
      height:12px;
    }
  }
  .year-row {
    position: relative;
    zoom: 1;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .month-row {
    position: relative;
    zoom: 1;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .month-mod {
    position: relative;
    padding-left: 42px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      font-size: 0;
      line-height: 0;
      display: inline-block;
      background: #a9b5c6;
      border-radius: 50%;
      z-index: 3;
      position: absolute;
      top: 5px;
      left: 24px;
    }
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      display: inline-block;
      border: 1px solid #a9b5c6;
      background: #fff;
      border-radius: 50%;
      font-size: 0;
      line-height: 0;
      margin-right: 8px;
      position: absolute;
      z-index: 2;
      top: 1px;
      left: 20px;
    }
  }
  .list {
    border-top: 1px solid #e8ecf2;
    padding: 20px 0;
    padding-right: 23px;
    position: relative;
    overflow: hidden;
    text-align: left;
    p {
      margin: 0;
    }
    .main-cent {
      position: relative;
      .cent-row {
        padding:  20px 15px;
        zoom: 1;
        display: flex;
        align-items: center;
        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
    dd {
      margin: 0;
    }
    .day-mod {
      background: #fff;
      border-radius: 5px;
      width: calc(100% - 60px);
      float: right;
      position: relative;
      margin: 0;
      padding-bottom: 0;
      .main-cent {
        border-radius: 4px;
        border: 1px solid #E8ECF2;

        margin: 40px 0 0 0;
        &::before {
          content: "";
          width: 10px;
          height: 10px;
          font-size: 0;
          line-height: 0;
          display: inline-block;
          background: #a9b5c6;
          border-radius: 50%;
          margin: 2px 2px 0;
          position: absolute;
          top: -28px;
          left: -39px;
          z-index: 10;
        }
        .time {
          color: #aeaeae;
          font-size: 12px;
          margin-bottom: 0;
          line-height: 24px;
          position: absolute;
          top: -32px;
          left: -20px;
          color: #333;
        }
      }
      .main-cent:hover{
        box-shadow: 1px 4px 6px rgba(80, 80, 80, 0.1);
        background-color: #ECF4FF;
      }
      .day-txt {
        position: absolute;
        top: -11px;
        left: -20px;
        color: #333;
      }
      &::before {
        content: none;
        width: 10px;
        height: 10px;
        font-size: 0;
        line-height: 0;
        display: inline-block;
        background: #a9b5c6;
        border-radius: 50%;
        margin: 2px 2px 0;
        position: absolute;
        top: -6px;
        left: -38px;
        z-index: 10;
      }
    }
    &:after {
      width: 2px;
      height: 96%;
      background: #dce5ec;
      content: " ";
      position: absolute;
      top: 20px;
      left: 28px;
    }
    .flowname {
      color: #636c78;
      max-width: 600px;
      font-size: 12px;
      word-wrap: break-word;
    }
    .head {
      width: 34px;
      height: 34px;
      float: left;
      border-radius: 50%;
      text-align: center;
      color: #2fa5dd;
      line-height: 34px;
      background-color:#46D48A ;
      font-size: 13px;
      font-weight: 400;
      color: #FFFFFF;
      .en-icon {
        margin-top: 5px;
      }
    }
    .cent {
      margin-left: 10px;
      float: left;
      p {
        line-height: 20px;
        font-size: 12px;
        word-break: break-all;
      }
      .name {
        display: flex;
        line-height: 24px;
        .main-name {
          color: #333;
          font-size: 13px;
          line-height: 24px;
          min-height: 24px;
          font-weight: bold;
        }
        .grant-name {
          color: #91a1b7;
          margin-left: 10px;
          .en-icon {
            color: #549ce1;
          }
          .txt {
            margin: 0 3px;
            color: #333;
          }
        }
      }
    }
    .info {
      float: right;
      text-align: right;
      .state {
        text-align: center;
        padding: 0 10px;
        line-height: 22px;
        border-radius: 10px;
        display: inline-block;
        background: #fff;
        color: #606060;
        border: 1px solid #e0e0e0;
      }
    }
    .btn-line {
      .show-process {
        color: #66a7e5;
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
      .btn-item {
        color: #636c78;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        .en-icon {
          color: #a9b5c6;
          margin-right: 5px;
          display: inline-block;
          vertical-align: middle;
        }
        .txt {
          display: inline-block;
          vertical-align: middle;
        }
        &:hover {
          color: #3e90fe;
          .en-icon {
            color: #3e90fe;
          }
        }
      }
    }
    .flow-notifier {
      font-size: 22px;
      margin-left: 10px;
      color: #cdcdcd;
      &:before {
        content: "\e790";
      }
    }
    .handsign-cent {
      float: left;
      width: 100%;
      text-align: center;
      img {
        width: 288px;
        display: block;
        margin: 0 auto;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
      }
    }
    &:first-child {
      .state {
        background: #48bff3;
        color: #fff;
        border: 1px solid #48bff3;
      }
    }
  }
  .seeFlow-cent {
    // padding:20px;
    background: #f5f7fa;
  }
  .el-collapse {
    border: 0;
  }
  /deep/ .el-collapse-item__header {
    // background-color: #f5f7fa;
    border: 0;
  }
  .el-collapse-item:last-child {
    margin-bottom: 0;
  }
  /deep/ .el-collapse-item__content {
    border-bottom: 0;
    padding-bottom: 0;
    font-size: 12px;
  }
  /deep/ .el-collapse-item__wrap {
    border-bottom: 0;
  }
}
.first-main{
  margin-top: 35px!important;
}
</style>
